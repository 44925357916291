import React from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useLocation } from "react-router-dom";

const getRedirectRoutePath = (status, answer) => {
  switch (status) {
    case "completed":
      if (
        answer.reviewAnswer.toLowerCase() === "red" &&
        answer.reviewRejectType.toLowerCase() === "final"
      )
        return "final-reject";
      else if (
        answer.reviewAnswer.toLowerCase() === "red" &&
        answer.reviewRejectType.toLowerCase() === "retry"
      )
        return "reject";
      else return "success";
  }
};
export default function Sumsub() {
  const search = useLocation().search;
  let token = new URLSearchParams(search).get("token");
  //   Handle Result Messages
  const messageHandler = (data, payload) => {
    if (data == "idCheck.applicantStatus") {
      const status = getRedirectRoutePath(
        payload.reviewStatus.toLowerCase(),
        payload.reviewResult
      );
      // if (status && status !== "reject") {
      //   window.location.href = `${process.env.REACT_APP_BASE_URL}/${status}`;
      // }
    }
  };

  //   Handle Error Message
  const handleUploadError = (error) => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/error`;
  };
  return token ? (
    <SumsubWebSdk
      accessToken={token}
      expirationHandler={() => Promise.resolve(token)}
      config={{
        lang: "en",
      }}
      //   options={options}
      onMessage={messageHandler}
      onError={handleUploadError}
    />
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img src="images/alert.png" alt="alert" height="75px" width="75px" />
      <p style={{ fontWeight: 500, fontSize: "24px" }}>No Token Found</p>
    </div>
  );
}
