import "./App.css";
import React from "react";
import Sumsub from "./pages/Sumsub";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// require('dotenv').config()



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sumsub" element={<Sumsub />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
